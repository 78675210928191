import { Autocomplete } from "@9amhealth/shared";
import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { Key } from "react-aria";
import { useFormContext } from "react-hook-form";
import { Item } from "react-stately";
import parseChoice from "src/lib/parseChoice";
import useQuestionnaireCubitInContext from "src/state/QuestionnaireCubit/useQuestionnaireCubitInContext";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import type { QuestionnaireParsedSelectChoice } from "src/ui/components/MultipleChoiceInput/MultipleChoiceInput";

export const DropdownSelectInput: FC<{
  field: QuestionnaireField;
  required: boolean;
}> = ({ field, required }) => {
  const options: QuestionnaireParsedSelectChoice[] = useMemo(
    () => parseChoice(field.properties?.choices, true),
    [field.properties?.choices]
  );

  const label = field.properties?.input_label;

  const formContext = useFormContext();
  const { setValue, getValues, register, clearErrors } = formContext;
  const [, { saveValue }] = useQuestionnaireCubitInContext();
  const initialValue = (getValues(field.id) ?? []) as string[];
  const [selected, setSelected] = useState<string>(initialValue[0]);

  register(field.id, { required });

  useEffect(() => {
    const value = (getValues(field.id) ?? []) as string[];
    setSelected(value[0]);
  }, [getValues, field.id, setSelected]);

  const setValueEverywhere = useCallback(
    (selectedIds: string[]) => {
      setValue(field.id, selectedIds);
      saveValue(field.id, selectedIds);
      setSelected(selectedIds[0]);

      for (const option of options) {
        const isSelected = selectedIds.includes(option.id);
        setValue(option.id, isSelected);
        saveValue(option.id, isSelected);
      }
    },
    [options, setValue, saveValue]
  );

  const handleChange = useCallback(
    (value: Key | null) => {
      clearErrors();
      if (!value) {
        return;
      }
      setValueEverywhere([value as string]);
    },
    [selected, setValueEverywhere]
  );

  return (
    <Autocomplete
      defaultItems={options}
      label={label}
      isRequired={required}
      defaultValue={selected}
      onSelectionChange={(val) => handleChange(val as Key)}
    >
      {(item) => <Item key={item.id}>{item.label}</Item>}
    </Autocomplete>
  );
};

export default DropdownSelectInput;
