import styled from "@emotion/styled";
import { isApiLink, isAppLink, rewriteAppLink } from "lib/Urls";
import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { OpenBrowser } from "src/hybrid/components/Browser";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import { toast } from "src/state/state";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import { PathnameToPopupMap } from "../AppQueryPopups/AppQueryPopupsRedirects";

interface Props {
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  highlight?: boolean;
  target?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledLink = styled(RouterLink as any)`
  color: var(
    --link-color,
    ${(p: { color?: string }): string => p.color ?? "#000"}
  );
  text-decoration: underline;

  &[data-highlight="true"] {
    color: var(--color-sunrise-blue, #80aeff);
    text-decoration: underline;
  }
`;

const POPUP_QUERY_PARAM = "popup";

const Link: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [invalidLink, setInvalidLink] = React.useState(false);
  const target = useMemo(() => {
    const toHref = props.to ?? "";

    // return only the search params if link is an old popup url
    const popupTarget = PathnameToPopupMap[toHref];
    if (popupTarget) {
      const currentPath = window.location.pathname;
      const searchParams = new URLSearchParams();
      searchParams.set(POPUP_QUERY_PARAM, popupTarget);
      return `${currentPath}?${searchParams.toString()}`;
    }

    return toHref;
  }, [props.to]);

  const downloadFile = (url: URL) => {
    const pathParts = url.pathname.split("/");
    const id = pathParts[pathParts.length - 1];
    toast.show("download.started");
    void FilesCubit.startFileDownload(id);
  };

  const to = useMemo(() => {
    const cleanLink = rewriteAppLink(target);
    if (!cleanLink) {
      setInvalidLink(true);
    }
    return cleanLink;
  }, [target]);

  const url = new URL(to, window.location.href);

  const appLink = isAppLink(url) || url.pathname.startsWith("/app");
  const apiLink = isApiLink(url);

  const isDownloadFile =
    apiLink && url.href.includes("/files") && !url.href.includes("/token");

  const isPopup = url.href.includes("dialog=true");

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClick?.(e);

    if (invalidLink) {
      toast.show("error.invalidLink");
      return;
    }

    if (isDownloadFile) {
      downloadFile(url);
    } else if (appLink) {
      navigate(to);
    } else if (isPopup) {
      const targetInnerText = e.currentTarget.innerText;
      AppQueryPopupsController.openPopup(AppPopup.iframe, {
        additionalParameters: {
          url: url.href,
          title: targetInnerText,
          stay: "false"
        }
      });
    } else {
      void OpenBrowser(url.href, {
        useBaseUrl: false,
        presentationStyle: "fullscreen"
      });
    }
  };

  return (
    <StyledLink
      data-highlight={props.highlight}
      {...props}
      to={to}
      onClick={handleClick}
      target={appLink ? undefined : "_blank"}
    >
      {props.children}
    </StyledLink>
  );
};

export default Link;
