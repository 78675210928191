import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { VideoPlayerLibInterface } from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import { tracker } from "src/state/state";
import videojs from "video.js";
import srt2webvtt from "lib/srt2webvtt";
import { Languages } from "src/constants/language";
import { StorageController } from "src/state/StorageBloc/StorageBloc";

export type FullVideoPlayerBlocState = {
  playing: boolean;
  ready: boolean;
  player?: VideoPlayerLibInterface;
};

type VideoTrack = {
  kind: "captions";
  default?: boolean;
  src: string;
  /* language in valid BCP 47 */
  srclang: string;
  label: string;
  format: "srt" | "vtt";
};

export type VideoPlayerOptions = {
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
  preload?: "auto" | "metadata" | "none";
  ratio?: number;
  sources?: {
    src: string;
    type: string;
  }[];
  tracks?: VideoTrack[];
};

export default class FullVideoPlayerBloc extends Cubit<FullVideoPlayerBlocState> {
  containerElement: HTMLDivElement | null = null;
  videoOptions?: VideoPlayerOptions;
  defaultOptions: VideoPlayerOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    muted: false,
    preload: "auto",
    sources: [],
    tracks: []
  };

  constructor() {
    super({
      playing: false,
      ready: false
    });
  }

  initVideo = async (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    const tracks = await this.parseTracks(videoOptions.tracks ?? []);
    const optionsMerged = {
      ...this.defaultOptions,
      ...videoOptions,
      playsinline: true,
      tracks,
      // use native controls for touch devices
      // in addiction, this allows the video to be played correctly in fullscreen mode
      //  when the app is wrapped in a webview or inappbrowser
      nativeControlsForTouch: true
    };
    if (this.containerElement !== null || containerElement === null) {
      return;
    }

    this.containerElement = containerElement;
    this.videoOptions = optionsMerged;

    const videoElement = document.createElement("video-js");

    videoElement.classList.add("vjs-big-play-centered");
    this.containerElement.appendChild(videoElement);

    const player = videojs(videoElement, optionsMerged, () => {
      this.emit({
        ...this.state,
        playing: videoOptions.autoplay === true,
        ready: true,
        player: player as unknown as VideoPlayerLibInterface
      });
      player.preload();

      this.addPlayerListeners();
    });
  };

  parseTracks = async (tracks: VideoTrack[]): Promise<VideoTrack[]> => {
    const parsedTracks: VideoTrack[] = [];

    const srtTracks = tracks.filter((track) => track.format === "srt");
    const supportedTracks = srtTracks.filter((track) => Languages.includes(track.srclang));

    const srtTracksFetch = supportedTracks.map((track) => {
      return fetch(track.src);
    });

    const content = await Promise.all(srtTracksFetch);
    const responseData = await Promise.all(content.map((e) => e.text()));
    const vttData = responseData.map((srt) => {
      const text = srt2webvtt(srt, {
        delay: 3800 // 3.8 seconds delay to sync the captions with the video, the intro from Sanctuary Health is about that long
      });

      const vttBlob = new Blob([text], { type: "text/vtt" });
      const blobURL = URL.createObjectURL(vttBlob);
      return blobURL;
    });
    const currentLanguage = StorageController.getItem("videoPlayerLanguage");
    for (let i = 0; i < supportedTracks.length; i++) {
      parsedTracks.push({
        ...supportedTracks[i],
        src: vttData[i],
        format: "vtt",
        default: currentLanguage === supportedTracks[i].srclang
      });
    }

    return parsedTracks;
  };

  errorCount = 0;
  errorLimit = 3;

  addPlayerListeners = () => {
    if (!this.state.player) {
      return;
    }

    const trackingEvents = [
      "play",
      "pause",
      "mute",
      "ended",
      "error",
      "ready",
      "fullscreenchange"
    ];

    const { player } = this.state;

    let currentLanguage = StorageController.getItem("videoPlayerLanguage")
    if (!currentLanguage) {
      currentLanguage = 'OFF';
    }

    player.on("texttrackchange", () => {
      const tracks = player.textTracks();
      const activeTrack = tracks.tracks_.find((track) => track.mode === "showing");
      const language = activeTrack?.language ?? '';
      const newLanguage = language === '' ? 'OFF' : language;
      StorageController.setItem("videoPlayerLanguage", newLanguage);

      if (currentLanguage !== newLanguage) {
        currentLanguage = newLanguage;
        this.trackEvent("languagechange", {
          textLanguage: currentLanguage
        });
      }
    });

    trackingEvents.forEach((eventName) => {
      player.on(eventName, () => {
        const duration = player.duration();
        const readyState = player.readyState();
        const networkState = player.networkState();
        const currentSrc = player.currentSrc();
        const bufferedPercent = player.bufferedPercent();

        const readyStateName: Record<number, string | undefined> = {
          0: "HAVE_NOTHING",
          1: "HAVE_METADATA",
          2: "HAVE_CURRENT_DATA",
          3: "HAVE_FUTURE_DATA",
          4: "HAVE_ENOUGH_DATA"
        };
        const readyStateNameString = readyStateName[readyState];

        const networkStateName: Record<number, string | undefined> = {
          0: "NETWORK_EMPTY",
          1: "NETWORK_IDLE",
          2: "NETWORK_LOADING",
          3: "NETWORK_NO_SOURCE"
        };
        const networkStateNameString = networkStateName[networkState];
        const error = player.error();

        const trackData = {
          video_playhead_seconds: Math.round(player.currentTime()),
          duration: isNaN(duration) ? -1 : Math.round(duration),
          fullscreen: player.isFullscreen(),
          currentSrc,
          bufferedPercent,
          readyState,
          readyStateNameString,
          networkState,
          networkStateNameString,
          errorCode: error?.code,
          errorMessage: error?.message,
          textLanguage: currentLanguage ?? 'OFF'
        };

        if (eventName === "error") {
          if (this.errorCount === 0) {
            this.trackEvent(eventName, trackData);
            reportErrorSentry(error);
          }

          this.errorCount++;
          setTimeout(() => {
            if (this.videoOptions && this.errorCount >= this.errorLimit) {
              this.reInitVideo(this.containerElement, this.videoOptions);
            }
          }, 1000);
        } else {
          this.trackEvent(eventName, trackData);
        }
      });
    });
  };

  trackEvent = (
    event: string,
    data: Record<string, boolean | number | string | undefined>
  ) => {
    tracker.track(`Video Player Interaction: ${event}` as TrackEvent, { data });
    this.log(event, data);
  };

  loggingEnabled = false;
  log = (event: string, ...data: unknown[]) => {
    if (!this.loggingEnabled) return;
    // eslint-disable-next-line no-console
    console.log(`VIDEO PLAYER EVENT: ${event}`, ...data);
  };

  reInitVideo = (
    containerElement: HTMLDivElement | null,
    videoOptions: VideoPlayerOptions
  ) => {
    this.disposePlayer();
    void this.initVideo(containerElement, videoOptions);
  };

  disposePlayer = () => {
    const { player } = this.state;
    if (player && !player.isDisposed()) {
      player.dispose();
    }
    this.containerElement = null;
    this.videoOptions = undefined;
    this.emit({
      ...this.state,
      playing: false,
      ready: false,
      player: undefined
    });
  };
}
