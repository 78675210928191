type Srt2WebvttOptions = {
  /**
   * The delay to apply to the WebVTT file in milliseconds
   * @default 0
   */
  delay?: number;
};

/**
 * Pad a time string with leading zeros
 * @param time - The time string to pad
 * @returns The padded time string
 */
function padTime(time: string | number, length: number = 2): string {
  return time.toString().padStart(length, "0");
}

/**
 * Convert a time string to a new time string with a delay
 * @param time - The time string to convert
 * @param options - The options for the conversion
 * @returns The converted time string
 */
function convertTime(time: string, options: Srt2WebvttOptions): string {
  const delay = options.delay || 0;
  const timeParts = time.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const [secondsString, millisecondsString] = timeParts[2].split(".");
  const seconds = parseInt(secondsString, 10);
  const milliseconds = parseInt(millisecondsString, 10);

  const timeInMs = (hours * 3600000) + (minutes * 60000) + (seconds * 1000) + milliseconds;
  const newTimeInMs = Math.max(0, timeInMs + delay);

  const newHours = Math.floor(newTimeInMs / 3600000);
  const newMinutes = Math.floor((newTimeInMs % 3600000) / 60000);
  const newSeconds = Math.floor((newTimeInMs % 60000) / 1000);
  const newMilliseconds = newTimeInMs % 1000;

  return `${padTime(newHours)}:${padTime(newMinutes)}:${padTime(newSeconds)}.${padTime(newMilliseconds, 3)}`;
}

/**
 * Convert SRT to WebVTT
 * @param caption - The SRT caption to convert
 * @returns The converted WebVTT caption
 */
function convertSrtCue(caption: string, options: Srt2WebvttOptions): string {
  // remove all html tags for security reasons
  //srt = srt.replace(/<[a-zA-Z\/][^>]*>/g, '');

  const cue: string[] = [];
  const s = caption.split(/\n/);

  // concatenate multi-line string separated in array into one
  while (s.length > 3) {
    for (let i = 3; i < s.length; i++) {
      s[2] += "\n" + s[i];
    }
    s.splice(3, s.length - 3);
  }

  let line = 0;

  // detect identifier
  if (!s[0].match(/\d+:\d+:\d+/) && s[1].match(/\d+:\d+:\d+/)) {
    const text = s[0].match(/\w+/)
    cue.push(text ? text[0] : "");
    cue.push("\n");
    line += 1;
  }

  // get time strings
  if (s[line].match(/\d+:\d+:\d+/)) {
    // convert time string
    const m = s[1].match(
      /(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/
    );
    if (m) {
      const startTime = convertTime(m[1] + ":" + m[2] + ":" + m[3] + "." + m[4], options);
      const endTime = convertTime(m[5] + ":" + m[6] + ":" + m[7] + "." + m[8], options);
      cue.push(`${startTime} --> ${endTime}`);
      cue.push("\n");
      line += 1;
    } else {
      // Unrecognized timestring
      return "";
    }
  } else {
    // file format error or comment lines
    return "";
  }

  // get cue text
  if (s[line]) {
    cue.push(s[line]);
    cue.push("\n\n");
  }

  return cue.join("");
}


/**
 * Convert SRT to WebVTT
 * @param data - The SRT data to convert
 * @param options - The options for the conversion
 * @returns The converted WebVTT data
 */
export default function srt2webvtt(
  data: string,
  options: Srt2WebvttOptions = {}
): string {
  // remove dos newlines
  let srt = data.replace(/\r+/g, "");
  // trim white space start and end
  srt = srt.replace(/^\s+|\s+$/g, "");

  // get cues
  const cuelist = srt.split("\n\n");
  let result = "";

  if (cuelist.length > 0) {
    result += "WEBVTT\n\n";
    for (let i = 0; i < cuelist.length; i = i + 1) {
      result += convertSrtCue(cuelist[i], options);
    }
  }

  return result;
}
