import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { decimalPattern, emailPattern } from "src/constants/patterns";
import formatPhoneNumber from "src/lib/formatPhoneNumber";
import parseNumber from "src/lib/parseNumber";
import useQuestionnaireCubitInContext from "src/state/QuestionnaireCubit/useQuestionnaireCubitInContext";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import {
    QuestionFieldValidation,
    QuestionnaireType
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import type { TranslationKey } from "src/types/translationKey";
import DateInput from "src/ui/components/DateInput/DateInput";
import DropdownSelectInput from "src/ui/components/DropdownSelectInput/DropdownSelectInput";
import HeightInput from "src/ui/components/HeightInput/HeightInput";
import MedicationInput from "src/ui/components/MedicationInput/MedicationInput";
import MultipleChoiceInput from "src/ui/components/MultipleChoiceInput/MultipleChoiceInput";
import MultipleTextField from "src/ui/components/MultipleTextField/MultipleTextField";
import OpinionScaleInput from "src/ui/components/OpinionScaleInput/OpinionScaleInput";
import PhoneNumberInput from "src/ui/components/PhoneNumberInput/PhoneNumberInput";
import QuestionnaireTextInput from "src/ui/components/QuestionnaireTextInput/QuestionnaireTextInput";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import WeightInput from "src/ui/components/WeightInput/WeightInput";
import YesNoInput from "src/ui/components/YesNoInput/YesNoInput";
import ZipCodeInput from "src/ui/components/ZipCodeInput/ZipCodeInput";

type InputValue = string[] | boolean | number | string | undefined;

const DynamicInput: FC<{
  field: QuestionnaireField;
  onComplete?: () => unknown;
  onChange?: (value: InputValue) => void;
  showContinueButton?: boolean;
}> = (props) => {
  const name = props.field.id;
  const { type } = props.field;
  const required =
    Boolean(props.field.validations?.required) ||
    props.showContinueButton === false;
  const label = props.field.properties?.input_label;
  const placeholder = props.field.properties?.input_placeholder;
  const [, { saveValue, customFormVariables }] =
    useQuestionnaireCubitInContext();
  const endAdornment = props.field.properties?.end_adornment ?? "";

  const { watch } = useFormContext();
  const inputValue = watch(name) as InputValue;

  useEffect(() => {
    if (!props.onChange) return;
    props.onChange(inputValue);
  }, [inputValue]);

  const options = props.field.properties?.choices ?? [];
  const optionsCount = options.length;
  const inputProps: Record<string, string> = {};
  const hasA1cValidation =
    props.field.properties?.validation_key === QuestionFieldValidation.a1c;

  const targetInput = useMemo(() => {
    switch (type) {
      case QuestionnaireType.SHORT_TEXT:
        return (
          <QuestionnaireTextInput
            type="text"
            label={label as TranslationKey}
            required={required}
            name={name}
          />
        );

      case QuestionnaireType.PHONE_NUMBER:
        return (
          <PhoneNumberInput
            name={name}
            label={(label ?? "phone.short") as TranslationKey}
            required={required}
            onNumberChange={(value): void =>
              saveValue(name, formatPhoneNumber(value))
            }
          />
        );

      case QuestionnaireType.DATE:
        return (
          <DateInput
            required={required}
            field={props.field}
            onChange={(field, date): void =>
              saveValue(field.id, date.format("YYYY-MM-DD"))
            }
            customFormVariables={customFormVariables}
          />
        );

      case QuestionnaireType.LONG_TEXT:
        return (
          <QuestionnaireTextInput
            type="text"
            multiline
            input="textarea"
            label={label as TranslationKey}
            required={required}
            name={name}
            placeholder={placeholder}
          />
        );

      case QuestionnaireType.EMAIL:
        return (
          <QuestionnaireTextInput
            type="email"
            required={required}
            name={name}
            label={(label ?? "email") as TranslationKey}
            autoCorrect="none"
            autoCapitalize="none"
            pattern={emailPattern}
          />
        );

      case QuestionnaireType.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceInput required={required} field={props.field} asList />
        );

      case QuestionnaireType.NUMBER:
        if (props.field.properties?.height_field) {
          return <HeightInput field={props.field} />;
        }
        if (props.field.properties?.weight_field) {
          return <WeightInput field={props.field} />;
        }

        if (hasA1cValidation) {
          inputProps.mask = "0[***]";
        } else {
          const { min_value = "", max_value = "" } =
            props.field.validations ?? {};
          inputProps.mask = `num{${min_value},${max_value}}`;
        }

        return (
          <QuestionnaireTextInput
            type="text"
            required={required}
            name={name}
            parseValue={parseNumber}
            autoCorrect="none"
            label={label as TranslationKey}
            autoCapitalize="none"
            pattern={decimalPattern}
            placeholder=""
            endAdornment={endAdornment}
            inputMode="decimal"
            maxLength="10"
            {...inputProps}
          />
        );

      case QuestionnaireType.YES_NO:
        return <YesNoInput required={required} field={props.field} />;

      case QuestionnaireType.DROPDOWN:
        if (optionsCount > 10)
          return (
            <DropdownSelectInput required={required} field={props.field} />
          );
        return <MultipleChoiceInput required={required} field={props.field} />;

      case QuestionnaireType.MEDICATION:
        return <MedicationInput field={props.field} />;

      case QuestionnaireType.MULTIPLE_TEXT:
        return <MultipleTextField field={props.field} />;

      case QuestionnaireType.ZIP_CODE:
        return (
          <ZipCodeInput
            required={required}
            label={label}
            placeholder={placeholder}
            field={props.field}
          />
        );

      case QuestionnaireType.OPINION_SCALE:
        return <OpinionScaleInput field={props.field} />;

      case QuestionnaireType.THANK_YOU:
      case QuestionnaireType.WELCOME:
      case QuestionnaireType.STATEMENT:
        return null;

      default:
        return (
          <ErrorBox data-severity="warning">
            Input type &quot;<strong>{String(type)}</strong>&quot; not
            supported.
          </ErrorBox>
        );
    }
  }, [props]);

  if (!targetInput) return null;

  return (
    <>
      <nine-spacer s="lg"></nine-spacer>
      {targetInput}
    </>
  );
};

export default DynamicInput;
