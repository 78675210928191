import styled from "@emotion/styled";
import { Cubit } from "blac";
import React, { FC, useEffect } from "react";
import envVariables from "src/lib/envVariables";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { CustomQuestionnaireChoice } from "src/state/QuestionnaireCubit/QuestionnaireState";
import useQuestionnaireCubitInContext from "src/state/QuestionnaireCubit/useQuestionnaireCubitInContext";
import { useBloc, userPreferences } from "src/state/state";
import { CmsImageRootObject } from "src/types/sanitySchema";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";

const InsuranceCard = styled.div`
  position: relative;
  width: 95%;
  margin-top: 1rem;

  .responsive-image {
    border-radius: 1rem;
    border: 6px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
  }

  :after {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    z-index: 1;

    border-radius: 1rem;
    background: linear-gradient(
      252deg,
      rgba(255, 209, 153, 0.75) -21.67%,
      rgba(250, 208, 232, 0.75) 37.45%,
      rgba(166, 198, 255, 0.75) 90.57%
    );
    transform: rotate(5deg) translate(7%, -2%);
  }
`;

class InsuranceCardBloc extends Cubit<{ image?: CmsImageRootObject }> {
  questionnaireCubit?: QuestionnaireCubit;

  constructor() {
    super({});
  }

  setQuestionnaireData = (questionnaireCubit: QuestionnaireCubit) => {
    this.questionnaireCubit = questionnaireCubit;
    void this.loadImage();
  };

  loadImage = async () => {
    const { insurance, employer } = this.getInsuranceInfo();

    const url = new URL(
      `${envVariables.CMS_API_BASE_URL}/api/content/insurance/card-image`
    );

    url.searchParams.append("insurance", insurance);
    url.searchParams.append("employer", employer);
    url.searchParams.append("language", userPreferences.language ?? "");

    const request = await fetch(url);
    const json = (await request.json()) as {
      image: null | CmsImageRootObject;
    };

    if (json.image) {
      this.emit({
        image: json.image
      });
    }
  };

  getInsuranceInfo = (): {
    insurance: string;
    employer: string;
  } => {
    const stateQuestionnaire = this.questionnaireCubit?.state;
    if (!stateQuestionnaire) return { insurance: "", employer: "" };

    const employerQuestion = stateQuestionnaire.fields.find((field) =>
      field.properties?.tags?.includes("employer-select")
    );
    const insuranceQuestion = stateQuestionnaire.fields.find((field) =>
      field.properties?.tags?.includes("insurance-select")
    );

    const [employer, insurance] = [employerQuestion, insuranceQuestion].map(
      (question) => {
        if (!question) return "";

        const override = this.questionnaireCubit?.answerOverrides.find(
          (override) => {
            return override.questionId === question.id;
          }
        );
        if (override) {
          return String(
            (override.fieldValue as CustomQuestionnaireChoice).value
          );
        }

        const fieldValue = (
          stateQuestionnaire.customFormData[question.id] as string[] | undefined
        )?.[0] as string;
        const choiceText = question.properties?.choices?.find(
          (choice) => choice.id === fieldValue
        )?.label;
        return String(choiceText);
      }
    );
    return { insurance, employer };
  };
}

const ExampleInsuranceCard: FC = () => {
  const [stateQuestionnaire, questionnaireCubit] =
    useQuestionnaireCubitInContext();
  const [stateInsuranceCard, { setQuestionnaireData }] = useBloc(
    InsuranceCardBloc,
    {
      create: () => new InsuranceCardBloc()
    }
  );

  useEffect(() => {
    setQuestionnaireData(questionnaireCubit);
  }, [stateQuestionnaire]);

  if (stateInsuranceCard.image) {
    return (
      <InsuranceCard>
        <ResponsiveImage image={stateInsuranceCard.image} />
      </InsuranceCard>
    );
  }

  return null;
};

export default ExampleInsuranceCard;
