import { Cubit } from "blac-next";
import { ComboBoxAria } from "react-aria";
import { ComboBoxState } from "react-stately";
import { AutocompleteComponentProps } from "./Autocomplete";

export type AutocompleteItem = object & {
  id: string;
  label?: string;
  name?: string;
};

type AutocompleteState = {
    hasFocus: boolean;
};

type AutocompleteProps<T extends AutocompleteItem> = {
    isTouch: boolean;
    componentProps: AutocompleteComponentProps<T>
};

export default class AutocompleteBloc<T extends AutocompleteItem> extends Cubit<AutocompleteState, AutocompleteProps<T>> {
    comboBoxState: ComboBoxState<T> | undefined;
    comboBoxProps: ComboBoxAria<T> | undefined;
    refs: {
        inputRef?: React.RefObject<HTMLInputElement | null>;
        popoverRef?: React.RefObject<HTMLDivElement | null>;
        listBoxRef?: React.RefObject<HTMLDivElement | null>;
        buttonRef?: React.RefObject<HTMLButtonElement | null>;
    } = {}
    isTouch: boolean;
    componentProps: AutocompleteComponentProps<T>

    constructor(props: AutocompleteProps<T>) {
        super({
            hasFocus: false
        });
        this.isTouch = props.isTouch;
        this.componentProps = props.componentProps;
    }

    connectComboBoxState = (comboBoxState: ComboBoxState<T>) => {
        this.comboBoxState = comboBoxState;
    }

    connectComboBoxProps = (comboBoxProps: ComboBoxAria<T>) => {
        this.comboBoxProps = comboBoxProps;
    }

    connectRefs = (refs: typeof this.refs) => {
        this.refs = refs;
    }

    setFocus = (hasFocus: boolean) => {
        this.emit({ ...this.state, hasFocus });
    };

    updatePopoverPosition = () => {
        requestAnimationFrame(() => {
            if (this.state.hasFocus && !this.isTouch && !this.comboBoxState?.isOpen) {
                this.comboBoxState?.open()
            }

            if (this.isTouch ||
                !this.refs.inputRef?.current ||
                !this.refs.popoverRef?.current) {
                return
            }

            const popoverRect = this.refs.popoverRef.current.getBoundingClientRect();
            const inputRect = this.refs.inputRef.current.getBoundingClientRect();
            const isAboveInput = popoverRect.bottom < inputRect.top + inputRect.height;
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            let maxHeight = popoverRect.height;

            if (isAboveInput) {
                const bottomPosition = windowHeight - inputRect.top;
                this.refs.popoverRef.current.style.bottom = `${bottomPosition}px`;
                this.refs.popoverRef.current.style.top = 'auto';
                maxHeight = windowHeight - inputRect.top;
            } else {
                const topPosition = inputRect.bottom + scrollY; 
                this.refs.popoverRef.current.style.top = `${topPosition}px`;
                this.refs.popoverRef.current.style.bottom = 'auto';
                maxHeight = windowHeight - inputRect.bottom;
            }

            this.refs.popoverRef.current.style.left = `${inputRect.left}px`; 
            this.refs.popoverRef.current.style.width = `${inputRect.width}px`; 
            this.refs.popoverRef.current.style.maxHeight = `${maxHeight}px`; 
        });
    };

    updateSelectedKeyFromDefaultValues = () => {
        // This logic should work for both, setting the initial state.selectedKey
        const initialKey = this.componentProps.defaultSelectedKey ?? this.componentProps.defaultValue;
        if (initialKey) {
            // Find the item in the initial list matching the default value/key
            const defaultItem = this.componentProps.defaultItems.find(
                (item) =>
                    item.id === initialKey ||
                    item.name === initialKey ||
                    item.label === initialKey
            );
            if (defaultItem) {
                // Use the item's actual ID (from the AutocompleteItem definition) as the key
                this.comboBoxState?.setSelectedKey(String(defaultItem.id));
            } else {
                // If not found in defaultItems, maybe it's already in props.items if provided?
                // Or maybe just set the key directly if it's expected to be valid?
                this.comboBoxState?.setSelectedKey(String(initialKey));
            }
        }
    }

    // --- Handlers (Desktop Specific) ---
    handleInputFocus = () => {
        if (this.isTouch) return;
        this.setFocus(true);
    };

    handleInputBlur = () => {
        if (this.isTouch) return;
        this.refs.inputRef?.current?.blur();
        this.setFocus(false);
    };

    handleButtonPress = () => {
        if (this.isTouch) return;
        this.setFocus(true);
        this.comboBoxState?.open();
    };

    handleButtonBlur = () => {
        if (this.isTouch) return;
        this.refs.buttonRef?.current?.blur();
        this.setFocus(false);
    }

    handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        this.comboBoxState?.setSelectedKey(selectedValue || null); // Update state
    };

    handleScrollEvent = (e: Event) => {
        const isInPopover = this.refs.popoverRef?.current?.contains(e.target as Node)
        if (!isInPopover) {
            this.updatePopoverPosition()
        }
    }

    tooltipEventAbortController: AbortController | undefined;
    addTooltipEventListeners = () => {
        if (this.isTouch || !this.comboBoxState?.isOpen) return; // Skip effect on mobile

        this.tooltipEventAbortController = new AbortController();
        const { signal } = this.tooltipEventAbortController;

        window.addEventListener("scroll", this.handleScrollEvent, { signal }); // Capture phase
        window.addEventListener("resize", this.handleScrollEvent, { signal });
        document.addEventListener("touchmove", this.handleScrollEvent, { signal });
        // Custom scroll events
        document.addEventListener("wheel", this.handleScrollEvent, { signal });
        document.addEventListener("ionScroll", this.updatePopoverPosition, { signal });
        document.addEventListener("nine-scroll", this.updatePopoverPosition, { signal });
    }


    removeTooltipEventListeners = () => {
        if (this.tooltipEventAbortController) {
            this.tooltipEventAbortController.abort();
        }
    }

    tooltipEventEffectHandler = () => {
        this.addTooltipEventListeners();
        return this.removeTooltipEventListeners;
    }
}