import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { useBloc } from "src/state/state";
import "video.js/dist/video-js.css";
import type { VideoPlayerOptions } from "./FullVideoPlayerBloc";
import FullVideoPlayerBloc from "./FullVideoPlayerBloc";

const VideoFrame = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--color-charcoal);
  box-sizing: border-box;

  .video-js {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .vjs-poster {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &[data-native="true"] {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

const FullVideoPlayer: FC<{
  options?: VideoPlayerOptions;
  native?: boolean;
}> = (props) => {
  const videoRef = React.useRef<HTMLDivElement>(null);

  const [, { initVideo, disposePlayer }] = useBloc(FullVideoPlayerBloc, {
    create: () => new FullVideoPlayerBloc()
  });

  React.useEffect(() => {
    if (props.options && videoRef.current) {
      void initVideo(videoRef.current, props.options);
    }
  }, [props.options, videoRef.current]);

  React.useEffect(() => {
    return () => {
      disposePlayer();
    };
  }, []);

  return (
    <VideoFrame data-vjs-player data-native={props.native}>
      <div ref={videoRef} />
    </VideoFrame>
  );
};
export default FullVideoPlayer;
