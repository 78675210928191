import { NineInput } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import type { Dispatch, FC, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import parseNumber from "src/lib/parseNumber";
import { cmToInch, FOOT_IN_INCHES, inchesToCm } from "src/lib/unitConversion";
import useQuestionnaireCubitInContext from "src/state/QuestionnaireCubit/useQuestionnaireCubitInContext";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 1em;
`;

const HeightInput: FC<{
  field: QuestionnaireField;
}> = (props) => {
  const name = props.field.id;
  const { getValues, setValue } = useFormContext();
  const cm = Number(getValues(name) ?? "");
  const [, { saveValue }] = useQuestionnaireCubitInContext();

  const inInches = cmToInch(cm);
  const [ft, setFt] = useState<number | string>(
    Math.floor(inInches / FOOT_IN_INCHES)
  );
  const [inches, setInches] = useState<number | string>(
    Math.round(inInches % FOOT_IN_INCHES)
  );

  const handleChange =
    (setFn: Dispatch<SetStateAction<number | string>>) =>
    (fn: Event): void => {
      const { value } = fn.target as HTMLInputElement;
      const number = parseNumber(value);
      setFn(number);
    };

  useEffect(() => {
    const convertedToCM = inchesToCm(
      Number(ft) * FOOT_IN_INCHES + Number(inches)
    );

    // only save if the value is not 0 and a valid number
    if (convertedToCM !== 0 && !isNaN(convertedToCM)) {
      saveValue(name, convertedToCM);
      setValue(name, convertedToCM);
    } else {
      saveValue(name, undefined);
      setValue(name, undefined);
    }
  }, [ft, inches]);

  return (
    <div>
      <Grid>
        <div>
          <OnEvent
            events={{
              [NineInput.customEvents.change]: handleChange(setFt)
            }}
          >
            <nine-input
              mask="0"
              placeholder="Feet"
              label=""
              unit="ft"
              required="true"
              value={ft ? `${ft}` : ""}
              maxLength="1"
              type="tel"
            ></nine-input>
          </OnEvent>
        </div>
        <div>
          <OnEvent
            events={{
              [NineInput.customEvents.change]: handleChange(setInches)
            }}
          >
            <nine-input
              mask="0[0]"
              validate-on-change
              required="true"
              placeholder="Inches"
              label=""
              unit="in"
              value={inches ? `${inches} ` : ""}
              type="tel"
            ></nine-input>
          </OnEvent>
        </div>
      </Grid>
    </div>
  );
};

export default HeightInput;
