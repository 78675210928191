import { useEffect, useState } from "react";

// Simple hook to detect mobile devices on the client-side
function useIsTouch(): boolean {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mediaQuery = window.matchMedia("(pointer:coarse)");
      const checkTouch = () => setIsTouch(mediaQuery.matches);

      // Initial check
      checkTouch();

      const abortController = new AbortController();
      const { signal } = abortController;

      // Listen for changes
      mediaQuery.addEventListener("change", checkTouch, { signal });

      // Cleanup
      return () => abortController.abort();
    }
  }, []);

  return isTouch;
}

export default useIsTouch;
