export enum Locale {
  en = "en-US",
  es = "es-US",
  esLatinAmerica = "es-419"
}

export enum Language {
  en = "en",
  es = "es"
}

export enum LanguageLong {
  en = "english",
  es = "spanish"
}

export default Language;

export const Languages = Object.keys(Language).map(
  (key) => Language[key as Language]
);

export const languageAsShort = (long?: string | LanguageLong): Language | undefined => {
  switch (long) {
    case LanguageLong.en:
      return Language.en;
    case LanguageLong.es:
      return Language.es;
  }
};
