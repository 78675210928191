// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CustomFieldPropertyRegex {
  public static GLOBAL_DISABLE_DATA_PREFILL = /\[disable-data-prefill\]/;
  public static STEP_SMS_NOTIFICATION_SUCCESS =
    /\[step-sms-notification-success\]/;
  public static DECIMAL = /\[decimal\]/;
  public static GROUP_COMMENT = /{([a-z]?.+)}/;
  public static SET = /\[(set)="(.*?)"\]/;
  public static SHOW_BUTTON = /\[show-button\]/;
  public static ONLY_LOGIC = /\[only-logic\]/;
  public static HIDE_BUTTON = /\[hide-button\]/;
  public static DESCRIPTION_FIRST = /\[description-first\]/;
  public static INPUT_DESCRIPTION = /\[(input-description)="(.*?)"\]/;
  public static BUTTON_TEXT = /\[(button-text)="(.*?)"\]/;
  public static ZIP_CODE_FIELD = /\[zip-code-field\]/;
  public static AGE_FIELD = /\[age-field\]/;
  public static ORDER_A1C_STEP = /\[order-a1c-step\]/;
  public static BUTTON_LINK = /\[(button-link)="(.*?)"\]/;
  public static BUTTON_POPUP = /\[(button-popup)="(.*?)"\]/;
  public static BUTTON_COLOR = /\[(button-color)="(.*?)"\]/;
  public static CENTER = /\[center\]/;
  public static AUTO_CONTINUE = /\[auto-continue\]/;
  public static BLANK_LINE = /^\s*[\r\n]/;
  public static CLEAR_OTHER = /\[clear-other\]/;
  public static AFFIX_SUFFIX = /\[(affix|suffix)="(.*?)"\]/;
  public static SET_USER_DOSAGE = /\[(set-user-dosage)="(.*?)"\]/;
  public static STYLE = /\[(style)="(.*?)"\]/;
  public static LABEL = /\[(label)="(.*?)"\]/;
  public static PLACEHOLDER = /\[(placeholder)="(.*?)"\]/;
  public static VALIDATION = /\[(validation)="(.*?)"\]/;
  public static MEDICATION_FIELD = /\[medication-field\]/;
  public static MEDICATION_FIELD_TIMES = /\[(medication-field-times)="(.*?)"\]/;
  public static MEDICATION_FIELD_UNITS = /\[(medication-field-units)="(.*?)"\]/;
  public static HEIGHT_FIELD = /\[height-field\]/;
  public static WEIGHT_FIELD = /\[weight-field\]/;
  public static WEIGHT_FIELD_CUSTOM = /\[(weight-field)="(.*?)"\]/;
  public static QUESTION_REFERENCE = /\[(question-reference)="(.*?)"\]/;
  public static MAXIMUM_DAILY_DOSAGE = /\[(max-daily-dosage)="(.*?)"\]/;
  public static SET_VAR = (): RegExp => /\[(set-var)="(.*?)"\]/gm;
  public static DOSAGE = /\[(dosage)="(.*?)"\]/;
  public static DEFAULT_VALUE = /\[(default-value)="(.*?)"\]/;
  public static ENDING = /\[ending\]/;
  public static INELIGIBLE = /\[ineligible\]/;
  public static ALWAYS_SEND_DATA = /\[always-send-data\]/;
  public static ANY_COMMAND_WITH_VALUE = /\[(.*?)="(.*?)"\]/;
  public static ANY_COMMAND_NO_VALUE = /\[(.*?)\](?!\()/;
  public static DATE_MAX_MIN = /\[(max\/min)="(.*?)"\]/;
  public static USE_VALUE = /\[(use-value)="(.*?)"\]/;
  public static MULTIPLE_TEXT_FIELD = /\[multiple-text-field\]/;
  public static DATA_KEY = /\[(data-key)="(.*?)"\]/;
  public static REQUIRED = /\[required\]/;
  public static DISABLED_DESCRIPTION = /\[(disabled-description)="(.*?)"\]/;
  public static TAGS = /\[(tags)="(.*?)"\]/;
}
