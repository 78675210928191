import {
  AutocompleteErrorEl,
  AutocompleteIcon,
  AutocompleteInputEl,
  AutocompleteInputWrap,
  AutocompleteLabelEl,
  AutocompleteTextEl
} from "atom/autocomplete/autocompleteComponents";
import { DropdownState, FormFieldState } from "atom/autoform/AutoFormBloc";
import styled from "@emotion/styled";
import { useElementDimensions } from "lib/useElementDimensions";
import { ListBox } from "molecule/dropdown/Dropdown";
import React, { useEffect, useId } from "react";
import {
  AriaComboBoxProps,
  AriaPopoverProps,
  DismissButton,
  Key,
  Overlay,
  useComboBox,
  useFilter,
  usePopover
} from "react-aria";
import { Button } from "react-aria-components";
import { OverlayTriggerState, useComboBoxState } from "react-stately";
import { z } from "zod";
import useIsTouch from "lib/useIsTouch";
import { useBloc } from "@blac/react";
import AutocompleteBloc, { AutocompleteItem } from "./AutocompleteBloc";

const AutocompleteCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 80vh;
  width: 100%;

  &[data-loading="true"] {
    filter: blur(2px);
    pointer-events: none;
  }
`;

const MobileNativeSelect = styled.select`
  width: 100%;
  position: absolute;
  inset: 0;
  height: 100%;
  padding: 8px 36px 8px 12px;
  margin: 0;
  border: none;
  border-radius: inherit;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 1;
  cursor: pointer;
`;

interface AutoCompletePopoverProps extends AriaPopoverProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
}

function AutoCompletePopover({
  children,
  state,
  ...props
}: AutoCompletePopoverProps) {
  const { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <div
        {...popoverProps}
        style={{
          ...popoverProps.style,
          overflowY: "scroll",
          backgroundColor: "white",
          boxShadow:
            "-82px 81px 46px rgba(69, 49, 22, 0.01), -46px 45px 39px rgba(69, 49, 22, 0.03), -20px 20px 29px rgba(69, 49, 22, 0.07), -5px 5px 16px rgba(69, 49, 22, 0.08), 0px 0px 0px rgba(69, 49, 22, 0.08)",
          borderRadius: "8px"
        }}
        ref={props.popoverRef as React.RefObject<HTMLDivElement>}
      >
        {children}
      </div>
      <DismissButton onDismiss={state.close} />
    </Overlay>
  );
}

export interface AutocompleteComponentProps<T extends AutocompleteItem>
  extends AriaComboBoxProps<T> {
  errorParser?: (props: AutocompleteComponentProps<T>) => string;
  defaultValue?: Key;
  defaultItems: T[];
  error?: string | z.ZodIssue;
  ref?: React.Ref<FormFieldState | null>;
  isLoading?: boolean;
  name?: string;
  enableInputLength?: number;
  placeholder?: string;
  allowsEmptyCollection?: boolean;
  forceDesktopMode?: boolean;
}

function Autocomplete<T extends AutocompleteItem>(
  p: AutocompleteComponentProps<T>
) {
  const {
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    },
    isLoading,
    enableInputLength = 5,
    ...props
  } = p;

  const isTouch = props.forceDesktopMode ? false : useIsTouch();
  const [
    { hasFocus },
    {
      connectRefs,
      connectComboBoxState,
      connectComboBoxProps,
      updatePopoverPosition,
      handleButtonBlur,
      handleInputBlur,
      handleInputFocus,
      handleSelectChange,
      handleButtonPress,
      tooltipEventEffectHandler,
      updateSelectedKeyFromDefaultValues
    }
  ] = useBloc(AutocompleteBloc<T>, {
    props: {
      componentProps: p,
      isTouch
    }
  });

  const enableInput =
    !isTouch &&
    (typeof props.items !== "undefined" ||
      props.defaultItems.length >= enableInputLength);

  const { contains } = useFilter({ sensitivity: "base" });

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const mobileSelectRef = React.useRef<HTMLSelectElement>(null); 
  connectRefs({
    buttonRef,
    inputRef,
    listBoxRef,
    popoverRef
  });

  const state = useComboBoxState({
    ...props,
    // Disable filtering on mobile by not passing defaultFilter
    defaultFilter: enableInput ? contains : undefined,
    onSelectionChange: (key) => {
      if (!isTouch) {
        handleButtonBlur();
        handleInputBlur();
      }
      props.onSelectionChange?.(key);
    }
  });
  connectComboBoxState(state);

  const comboBoxProps = useComboBox(
    {
      ...props,
      inputRef: inputRef, 
      buttonRef: buttonRef,
      listBoxRef: listBoxRef,
      popoverRef: popoverRef,
      onBlur: handleInputBlur,
      onFocus: handleInputFocus
    },
    state
  );
  const {
    inputProps,
    listBoxProps,
    labelProps: comboBoxLabelProps
  } = comboBoxProps;
  connectComboBoxProps(comboBoxProps);

  useEffect(tooltipEventEffectHandler, [isTouch, state.isOpen]);

  useEffect(updatePopoverPosition, [
    isTouch,
    enableInput,
    hasFocus,
    state.isOpen
  ]);

  const [{ width }] = useElementDimensions(
    inputRef as React.RefObject<HTMLInputElement>
  ); 

  React.useImperativeHandle(
    props.ref,
    () => {
      return {
        setValue: (value: string) => {
          state.setSelectedKey(value);
        }
      } as DropdownState;
    },
    [state] 
  );

  const errorString = errorParser(props);

  useEffect(updateSelectedKeyFromDefaultValues, [
    props.defaultSelectedKey,
    props.defaultValue,
    props.defaultItems
  ]);

  const selectId = useId();
  const labelId = useId();

  const itemsToRender = props.defaultItems;
  const noOptions = itemsToRender.length === 0;
  const selectedKey = state.selectedKey ?? "";

  // === Render ===
  return (
    <div
      style={{ position: "relative", display: "inline-block", width: "100%" }}
    >
      <AutocompleteInputWrap data-invalid={!!props.isInvalid}>
        {/* Label */}
        {props.label && (
          <AutocompleteLabelEl
            id={labelId}
            // Associate label with the correct input element
            htmlFor={isTouch ? selectId : inputProps.id}
            // Apply react-aria props only on desktop
            {...(isTouch ? {} : comboBoxLabelProps)}
            // Adjust state check for label positioning
            data-hasvalue={
              isTouch
                ? Boolean(state.selectedKey)
                : Boolean(state.inputValue) || Boolean(state.selectedItem)
            }
            // No popover expansion concept on mobile
            data-expanded={
              isTouch ? false : state.isOpen || Boolean(state.selectedItem)
            }
            className="body1"
          >
            {props.label}
          </AutocompleteLabelEl>
        )}

        {isTouch ? (
          // === Mobile: Native Select ===
          <MobileNativeSelect
            id={selectId}
            ref={mobileSelectRef}
            name={props.name}
            aria-labelledby={labelId}
            value={selectedKey}
            onChange={handleSelectChange}
            disabled={props.isDisabled}
            aria-invalid={props.isInvalid}
          >
            {props.allowsEmptyCollection ? (
              <>
                <option value="">
                  {props.placeholder ||
                    (noOptions ? "No options available" : "Select an option")}
                </option>
              </>
            ) : (
              <>
                {(!state.selectedKey || selectedKey === "" || noOptions) && (
                  <option value="" disabled>
                    {noOptions
                      ? "No options available"
                      : props.placeholder || "Select an option"}
                  </option>
                )}
              </>
            )}

            {/* Render items from state */}
            {itemsToRender.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </MobileNativeSelect>
        ) : null}

        <AutocompleteInputEl
          {...inputProps} // Spread props from useComboBox
          style={{
            pointerEvents: isTouch ? "none" : "auto"
          }}
          ref={inputRef}
          data-invalid={!!props.isInvalid}
          disabled={!enableInput || props.isDisabled}
          aria-hidden={!enableInput || isTouch}
        />
        {/* Overlay button for non-filterable/dropdown-only mode */}
        {!isTouch && !enableInput && !props.isDisabled && (
          <Button
            ref={buttonRef}
            aria-label={
              typeof props.label === "string" && props.label
                ? `Show options for ${props.label}`
                : "Show options"
            } 
            aria-labelledby={inputProps["aria-labelledby"]}
            aria-controls={
              state.isOpen ? inputProps["aria-controls"] : undefined
            }
            onPress={handleButtonPress}
            onBlur={handleButtonBlur}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.001,
              cursor: "pointer"
            }}
          />
        )}
        <AutocompleteIcon />
      </AutocompleteInputWrap>

      {/* Desktop Popover (Conditionally Rendered) */}
      {!isTouch && state.isOpen && (
        <AutoCompletePopover
          state={state}
          triggerRef={inputRef}
          popoverRef={popoverRef}
          isNonModal
          placement="bottom start"
        >
          <AutocompleteCard data-loading={isLoading} style={{ width: width }}>
            <ListBox
              {...listBoxProps} // Pass props from useComboBox
              listBoxRef={listBoxRef}
              state={state} // Pass state to ListBox
            />
          </AutocompleteCard>
        </AutoCompletePopover>
      )}

      {/* Description and Error (Common) */}
      {props.description && (
        <AutocompleteTextEl slot="description" className="little1">
          {props.description}
        </AutocompleteTextEl>
      )}
      {errorString && (
        <AutocompleteErrorEl className={"little1 data-field-error"}>
          {errorString}
        </AutocompleteErrorEl>
      )}
    </div>
  );
}

export default Autocomplete;
