import { useBlocNext } from "@blac/react";
import { useQuestionnaireId } from "./QuestionnaireCubitIdProvider";
import QuestionnaireCubit from "./QuestionnaireCubit";

const useQuestionnaireCubitInContext = () => {
  const id = useQuestionnaireId();
  if (!id) {
    throw new Error("No questionnaire ID found in context");
  }
  return useBlocNext(QuestionnaireCubit, {
    id,
    props: {
      questionnaireId: id
    }
  });
};

export default useQuestionnaireCubitInContext;
